<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('auth/logout')
      .then(() => {
        this.$toasted.success(this.$t('successful_logout'));
        this.$router.push('login');
      }).catch(() => {});
  },
}
</script>
